import React from 'react'
import Vpsheader from './Vpsheader'
import VpsProducts from './VpsProducts'

export default function ProductDetail() {
  return (
    <>
    <Vpsheader/>
    <VpsProducts/>
    </>
  )
}
